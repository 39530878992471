import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faMoon } from '@fortawesome/free-regular-svg-icons/faMoon';
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import { faStop } from '@fortawesome/free-solid-svg-icons/faStop';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons/faLightbulb';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons/faCommentAlt';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard';
import { faCommentAlt as faCommentAltSolid } from '@fortawesome/free-solid-svg-icons/faCommentAlt';
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faBell as faBellSolid } from '@fortawesome/free-solid-svg-icons/faBell';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons/faCartArrowDown';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faDice } from '@fortawesome/free-solid-svg-icons/faDice';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons/faMoneyBillWave';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faEye as faEye2 } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faUserTag } from "@fortawesome/free-solid-svg-icons/faUserTag";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons/faTicketAlt'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import { faFileAudio } from '@fortawesome/free-solid-svg-icons/faFileAudio'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons/faPeopleGroup";
import { faVolleyball } from "@fortawesome/free-solid-svg-icons/faVolleyball";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { faIcons } from "@fortawesome/free-solid-svg-icons/faIcons";
import { faCompassDrafting } from "@fortawesome/free-solid-svg-icons/faCompassDrafting";
import { faBasketShopping } from "@fortawesome/free-solid-svg-icons/faBasketShopping";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faForward } from "@fortawesome/free-solid-svg-icons/faForward";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCircle } from "@fortawesome/free-regular-svg-icons/faCircle";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons/faPlayCircle";
import { faHomeAlt } from "@fortawesome/free-solid-svg-icons/faHomeAlt";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faDatabase } from "@fortawesome/free-solid-svg-icons/faDatabase";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faForwardStep } from "@fortawesome/free-solid-svg-icons/faForwardStep";
import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons/faHourglassEnd";
import { faPalette } from '@fortawesome/free-solid-svg-icons/faPalette';
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons/faPuzzlePiece";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faSms } from "@fortawesome/free-solid-svg-icons/faSms";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faCogs } from "@fortawesome/free-solid-svg-icons/faCogs";
import { faGift } from "@fortawesome/free-solid-svg-icons/faGift";
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage";
import { faFileText } from "@fortawesome/free-solid-svg-icons/faFileText";
import { faTrophy } from "@fortawesome/free-solid-svg-icons/faTrophy";
import { faSchool } from "@fortawesome/free-solid-svg-icons/faSchool";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faRectangleList } from "@fortawesome/free-solid-svg-icons/faRectangleList";
import { faTicket } from "@fortawesome/free-solid-svg-icons/faTicket";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faChampagneGlasses } from "@fortawesome/free-solid-svg-icons/faChampagneGlasses";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import { faMoneyBill1Wave } from "@fortawesome/free-solid-svg-icons/faMoneyBill1Wave";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faCubes } from "@fortawesome/free-solid-svg-icons/faCubes";
import { faRankingStar } from "@fortawesome/free-solid-svg-icons/faRankingStar";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faFileImport } from "@fortawesome/free-solid-svg-icons/faFileImport";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons/faWandMagicSparkles";
import { faAward } from "@fortawesome/free-solid-svg-icons/faAward";

const icons = {
    fa: [
        faTriangleExclamation,
        faHourglassStart,
        faStop,
        faPause,
        faBars,
        faAddressCard,
        faDollarSign,
        faCircleExclamation,
        faEye2,
        faTrophy,
        faBasketShopping,
        faForwardStep,
        faCreditCard,
        faHourglassEnd,
        faDatabase,
        faDownload,
        faChartLine,
        faChartBar,
        faDesktop,
        faHomeAlt,
        faPlayCircle,
        faPlay,
        faCheck,
        faForward,
        faPaperPlane,
        faCircle,
        faCircleCheck,
        faQuestion,
        faCompassDrafting,
        faIcons,
        faCoins,
        faHandshake,
        faCheckSquare,
        faMoon,
        faLightbulb,
        faStar,
        faStarSolid,
        faExpand,
        faCommentAlt,
        faCommentAltSolid,
        faBell,
        faBellSolid,
        faShoppingCart,
        faCartArrowDown,
        faSearch,
        faUsers,
        faUserTag,
        faUserTie,
        faBuilding,
        faBalanceScale,
        faDice,
        faMoneyBillWave,
        faInfo,
        faTrashAlt,
        faEdit,
        faEye,
        faPlus,
        faTicketAlt,
        faExternalLinkAlt,
        faImage,
        faVideo,
        faFileAudio,
        faArrowLeft,
        faArrowRight,
        faPeopleGroup,
        faVolleyball,
        faUser,
        faArrowUp,
        faArrowDown,
        faPalette,
        faPuzzlePiece,
        faEnvelope,
        faSms,
        faMoneyBill,
        faGlobe,
        faCogs,
        faGift,
        faLanguage,
        faFileText,
        faSchool,
        faList,
        faCircleInfo,
        faRectangleList,
        faTicket,
        faBook,
        faChampagneGlasses,
        faHandHoldingDollar,
        faMoneyBill1Wave,
        faBan,
        faCubes,
        faRankingStar,
        faGear,
        faFileImport,
        faWandMagicSparkles,
        faAward,
    ],
};

export default icons;