// noinspection JSUnusedGlobalSymbols

export const GET_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    query($game: GameAuthInput) {
        getPrivateGame: getPrivateOrganizationGame(game: $game) {
            id
            privateToken
            name
            status
            demoBookPublicTicketsPdfUrl
            drawTicketsPdfUrl
            demoDrawTicketsPdfUrl
            resultsPublishingStatus
            participantsNotificationStatus
            winningParticipantsNotificationStatus
            loosingParticipantsNotificationStatus
            publicLiveDrawPageUrl
            organizationName
            statPaidSales
            plannedTickets
            statTickets
            statPreorders
            statPromises
            statGodfathers
            statFirstPaidSaleCompletedGodfathers
            statCustomerCollectedAmount
            ticketPriceCurrency
            ticketPriceAmount
            salesExportPrivateUrl
            ticketsExportPrivateUrl
            bunchesExportPrivateUrl
            sponsoringsExportPrivateUrl
            sellersExportPrivateUrl
            sellergroupsExportPrivateUrl
            promisesExportPrivateUrl
            winnersExportPrivateUrl
            presultsExportPrivateUrl
            organizationOwnerEmail
            organizationOwnerLastName
            organizationOwnerFirstName
            organizationOwnerPhone
            organizationCustomer
            country
            locale
            publicPageUrl
            publicFlyerUrl
            mainDfontfamily
            headingDfontfamily
            textDfontfamily
            buttonDfontfamily
            sellersVisibilityMode
        }
    }
`;
export const SETUP_PRIVATE_ORGANIZATION_GAME_CUSTOMER_PAYMENTMETHOD = (gql: any) => gql`
    mutation($game: GameAuthInput) {
        setupPrivateOrganizationGameCustomerPaymentmethod(game: $game) {
            paymentProviderPaymentMethodSetupPageUrl
        }
    }
`;
export const REQUEST_PRIVATE_ORGANIZATION_GAME_CONTACT_ME = (gql: any) => gql`
    mutation($game: GameAuthInput, $reason: String) {
        requestPrivateOrganizationGameContactMe(game: $game, reason: $reason) {
            id
        }
    }
`;
export const GET_PRIVATE_GAME = (gql: any) => gql`
    query($game: GameAuthInput) {
        getPrivateGame: getPrivateOrganizationGame(game: $game) {
            id
            privateToken
            ticketsPrivateToken
            code
            name
            status
            resultsPdfUrl
            demoBookPublicTicketsPdfUrl
            drawTicketsPdfUrl
            demoDrawTicketsPdfUrl
            resultsPublishingStatus
            participantsNotificationStatus
            winningParticipantsNotificationStatus
            loosingParticipantsNotificationStatus
            publicLiveDrawPageUrl
            organizationName
            statImportedTickets
            statPaidSales
            statBooks
            statPaidBookpayments
            statBooksets
            statPreorders
            statPromises
            statGodfathers
            statSellers
            statSellergroups
            statFirstPaidSaleCompletedGodfathers
            plannedTickets
            statTickets
            statCustomerCollectedAmount
            ticketPriceCurrency
            ticketPriceAmount
            salesExportPrivateUrl
            ticketsExportPrivateUrl
            bunchesExportPrivateUrl
            sponsoringsExportPrivateUrl
            sellersExportPrivateUrl
            sellergroupsExportPrivateUrl
            promisesExportPrivateUrl
            preordersExportPrivateUrl
            winnersExportPrivateUrl
            presultsExportPrivateUrl
            paidBooksSalesExportPrivateUrl
            booksExportPrivateUrl
            bookpaymentsExportPrivateUrl
            organizationOwnerEmail
            organizationOwnerLastName
            organizationOwnerFirstName
            organizationOwnerPhone
            organizationCustomer
            organizationCustomerPaymentmethods {
                items {
                    id
                    name
                    type
                }
            }
            overline
            presentation
            organizationOwner
            features
            automationFeatures
            statPaidDonationsAmount
            statPaidSales
            statSales
            logoImage {
                url
                available
            }
            bannerImage {
                url
                available
            }
            notificationParams
            flexModeStatus
            publicQrCodeUrl
            publicPageShortUrl
            statPaidAmount
            plan
            planCode
            planName
            planMaxSales
            planMaxTickets
            planMaxPaymentAmount
            plannedDrawnAt
            plannedStartedAt
            ticketPriceCurrency
            ticketPriceAmount
            country
            locale
            publicPageUrl
            publicFlyerUrl
            supportedLocales
            bunches {
                items {
                    id
                    drawAvailabilityStatus
                    name
                    quantity
                    price
                    currency
                    description
                    rank
                    sponsoring
                    sponsoringName
                    sponsoring2
                    sponsoring2Name
                    sponsoring3
                    sponsoring3Name
                    sponsoring4
                    sponsoring4Name
                    sponsoring5
                    sponsoring5Name
                    sponsoringMode
                    mainImage {
                        url
                        available
                    }
                    image1Image {
                        url
                        available
                    }
                    image2Image {
                        url
                        available
                    }
                    image3Image {
                        url
                        available
                    }
                    image4Image {
                        url
                        available
                    }
                    image5Image {
                        url
                        available
                    }
                    image6Image {
                        url
                        available
                    }
                    image7Image {
                        url
                        available
                    }
                    image8Image {
                        url
                        available
                    }
                    image9Image {
                        url
                        available
                    }
                    wonQuantity
                    wonStatus
                }
            }
            sponsorings {
                items {
                    id
                    name
                    description
                    websiteUrl
                    mainImage {
                        url
                        available
                    }
                    image1Image {
                        url
                        available
                    }
                    image2Image {
                        url
                        available
                    }
                    image3Image {
                        url
                        available
                    }
                    image4Image {
                        url
                        available
                    }
                    image5Image {
                        url
                        available
                    }
                    image6Image {
                        url
                        available
                    }
                    image7Image {
                        url
                        available
                    }
                    image8Image {
                        url
                        available
                    }
                    image9Image {
                        url
                        available
                    }
                    logoImage {
                        url
                        available
                    }
                }
            }
            mainThemeCode
            publicPageBackgroundTexture
            publicPageEffects
            publicPageShortUrl
            mainVideoUrl
            gvideos {
                items {
                    id
                    mainVideoUrl
                }
            }
            gimages {
                items {
                    id
                    rank
                    token
                    tags
                    mainImage {
                        url
                        name
                        contentType
                        available
                    }
                }
            }
            domain
            subject
            customOwnerForm
            inputs {
                items {
                    id
                    code
                    name
                    rank
                    type
                    description
                    required
                    defaultValue
                    params
                }
            }
            publicFeatures
            publicCommunicationPageShortUrl
            fullName
            nin
            publicEmail
            publicPhone
            websiteUrl
            description
            longDescription
            infos
            warning
            agenda
            saleMandatoryOptInMessage
            bonusMessage
            countDownText
            statPaidSales
            faviconImage {
                url
                available
            }
            appleTouchImage {
                url
                available
            }
            twitterShareImage {
                url
                available
            }
            facebookShareImage {
                url
                available
            }
            termsPdfFile {
                url
                available
            }
            notifyEmail
            integrations {
                items {
                    id
                    code
                    privateToken
                }
            }
            organizationCustomerName
            organizationCustomerLastName
            organizationCustomerFirstName
            organizationCustomerEmail
            organizationCustomerPhone
            organizationCustomerStreet
            organizationCustomerComplement
            organizationCustomerZipCode
            organizationCustomerCity
            organizationCustomerCountry
            organizationCustomerVatNumber
            sellergroups {
                items {
                    code
                    createdAt
                    customCode
                    description
                    email
                    game
                    id
                    name
                    phone
                    presentation
                    websiteUrl
                    publicToken
                    logoImage {
                        url
                        available
                    }
                }
            }
            sellers {
                items {
                    code
                    createdAt
                    customCode
                    description
                    email
                    game
                    id
                    firstName
                    lastName
                    phone
                    presentation
                    websiteUrl
                    sellergroupName
                    sellergroup
                }
            }
            drawableStatus
            drawNbWinningTickets
            publicPagePublishingStatus
            publicPageModulesData
            mainDfontfamily
            headingDfontfamily
            textDfontfamily
            buttonDfontfamily
            godfathersExportPrivateUrl
            currentPeriodAmountGodfathersExportPrivateUrl
            currentPeriodSalesGodfathersExportPrivateUrl
            currentPeriodTicketsGodfathersExportPrivateUrl
            generalAmountGodfathersExportPrivateUrl
            generalSalesGodfathersExportPrivateUrl
            generalTicketsGodfathersExportPrivateUrl
            previousPeriodAmountGodfathersExportPrivateUrl
            previousPeriodSalesGodfathersExportPrivateUrl
            previousPeriodTicketsGodfathersExportPrivateUrl
            generalAmountSellersExportPrivateUrl
            generalSalesSellersExportPrivateUrl
            generalTicketsSellersExportPrivateUrl
            generalAmountSellergroupsExportPrivateUrl
            generalSalesSellergroupsExportPrivateUrl
            generalTicketsSellergroupsExportPrivateUrl
            importableTicketCredits
            sellersVisibilityMode
            collectModeMessage
            collectModeEmail
            collectModePhone
            customPacks
            customBonuses
        }
    }
`;

export const UPDATE_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation($game: GameAuthInput!, $data: UpdatePrivateOrganizationGameInput) {
        updatePrivateOrganizationGame(game: $game, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_GAME = (gql: any) => gql`
    mutation($game: GameAuthInput!, $data: UpdatePrivateOrganizationGameInput) {
        updatePrivateGame: updatePrivateOrganizationGame(game: $game, data: $data) {
            id
        }
    }
`;
export const CREATE_PRIVATE_GAME_REQUEST_MESSAGE = (gql: any) => gql`
    mutation($game: GameAuthInput!, $data: CreatePrivateOrganizationGameRequestMessageInput) {
        createPrivateGameRequestMessage: createPrivateOrganizationGameRequestMessage(game: $game, data: $data) {
            id
        }
    }
`;
export const GET_PUBLIC_GAME_BY_CODE = (gql: any) => gql`
    query($code: String!) {
        getPublicGameByCode(code: $code) {
            name
            organizationName
            availableContacts
        }
    }
`;

export const FIND_PRIVATE_PAID_GAME_SALES = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivatePaidGameSales(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                paymentAmount
                donation
                tickets
                email
                lastName
                firstName
                phone
                createdAt
                paidAt
                paymentProvider
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_GODFATHERS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameGodfathers(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                email
                lastName
                firstName
                phone
                createdAt
                code
                statPaidTickets
                statPaidSales
                statPaidAmount
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_SELLERS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameSellers(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                email
                lastName
                firstName
                phone
                createdAt
                code
                statPaidTickets
                statPaidSales
                statPaidAmount
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_SELLERGROUPS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameSellergroups(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                email
                name
                phone
                createdAt
                code
                statPaidTickets
                statPaidSales
                statPaidAmount
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_GODFATHERS_RANKING = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String, $period: String!, $dimension: String!) {
        findPrivateGameGodfathersRanking(game: $game, cursor: $cursor, period: $period, dimension: $dimension) {
            cursor
            items {
                id
                email
                lastName
                firstName
                phone
                createdAt
                code
                statPaidTickets
                statPaidSales
                statPaidAmount
                statCurrentPeriodPaidTickets
                statCurrentPeriodPaidSales
                statCurrentPeriodPaidAmount
                statPreviousPeriodPaidTickets
                statPreviousPeriodPaidSales
                statPreviousPeriodPaidAmount
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_SELLERS_RANKING = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String, $dimension: String!) {
        findPrivateGameSellersRanking(game: $game, cursor: $cursor, dimension: $dimension) {
            cursor
            items {
                id
                email
                lastName
                firstName
                phone
                createdAt
                code
                statPaidTickets
                statPaidSales
                statPaidAmount
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_SELLERGROUPS_RANKING = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String, $dimension: String!) {
        findPrivateGameSellergroupsRanking(game: $game, cursor: $cursor, dimension: $dimension) {
            cursor
            items {
                id
                email
                name
                phone
                createdAt
                code
                statPaidTickets
                statPaidSales
                statPaidAmount
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_TICKETS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameTickets(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                email
                lastName
                firstName
                phone
                createdAt
                code
                customCode
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_BOOKS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameBooks(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                email
                lastName
                firstName
                phone
                createdAt
                code
                customCode
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_BOOKSETS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameBooksets(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                bookList
                status
                createdAt
            }
        }
    }
`;
export const FIND_PRIVATE_PAID_GAME_BOOKPAYMENTS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivatePaidGameBookpayments(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                paymentAmount
                donation
                tickets
                email
                lastName
                firstName
                phone
                createdAt
                paidAt
            }
        }
    }
`;
export const FIND_PRIVATE_NOT_COMPLETED_GAME_PROMISES = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateNotCompletedGamePromises(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                donation
                tickets
                email
                lastName
                firstName
                phone
                createdAt
            }
        }
    }
`;
export const FIND_PRIVATE_NOT_CONVERTED_GAME_PREORDERS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateNotConvertedGamePreorders(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                donation
                tickets
                email
                lastName
                firstName
                phone
                createdAt
                sharedCode
                paymentProvider
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_WINNERS = (gql: any) => gql`
    query($game: GameAuthInput, $cursor: String) {
        findPrivateGameWinners(game: $game, cursor: $cursor) {
            cursor
            items {
                id
                bunchName
                bunchRank
                code
                email
                firstName
                lastName
                phone
                rank
                status
                ticketCode
                ticketCustomCode
                ticketSellerOnly
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_AVAILABLE_PAYMENT_MODULES = (gql: any) => gql`
    query($game: GameAuthInput, $limit: Int = 100) {
        findPrivateGameAvailablePaymentModules(game: $game, limit: $limit) {
            id
            code
            name
            rate
            flatTax
            currency
            url
            enabled
            public
            priority
            tags
            externalVideoUrl
            mainVideoUrl
            officialHelpUrl
            officialCreateAccountUrl
            countries
            mode
            kycProcess
            reconnectable
        }
    }
`;
export const FIND_PRIVATE_GAME_AVAILABLE_PUBLIC_PLANS = (gql: any) => gql`
    query($game: GameAuthInput, $limit: Int = 100) {
        findPrivateGameAvailablePublicPlans(game: $game, limit: $limit) {
            id
            code
            maxPaymentAmount
            maxSales
            maxTickets
            name
            priceAmount
            priceCurrency
            priceMode
        }
    }
`;

export const FIND_PRIVATE_GAME_ORGANIZATION_TYPES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameOrganizationTypes(game: $game) {
            code
            name
            icon
            group
        }
    }
`;
export const FIND_PRIVATE_GAME_COUNTRIES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameCountries(game: $game) {
            code
            name
            mainImage {
                url
            }
        }
    }
`; export const FIND_PRIVATE_GAME_LOCALES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameLocales(game: $game) {
            code
            name
            mainImage {
                url
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_SUBJECTS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameSubjects(game: $game) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_OPTIONS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameOptions(game: $game) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_THEMES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameThemes(game: $game) {
            code
            name
            colors
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_TYPES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameTypes(game: $game) {
            code
            name
            icon
            gameRank
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_TEXTURES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameTextures(game: $game) {
            code
            name
            mainImage {
                url
            }
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_EFFECTS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameEffects(game: $game) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_FONTS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameGameFonts(game: $game) {
            id
            code
            name
        }
    }
`;
export const FIND_PRIVATE_GAME_SALE_DATAS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameSaleDatas(game: $game) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_GAME_TICKET_PRICE_AMOUNTS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameTicketPriceAmounts(game: $game) {
            value
        }
    }
`;
export const FIND_PRIVATE_GAME_PLANNED_TICKETS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGamePlannedTickets(game: $game) {
            value
        }
    }
`;
export const FIND_PRIVATE_GAME_CUSTOM_PACKS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameCustomPacks(game: $game) {
            value
        }
    }
`;
export const FIND_PRIVATE_GAME_CUSTOM_BONUSES = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateGameCustomBonuses(game: $game) {
            value
        }
    }
`;
export const FIND_PRIVATE_ORGANIZATION_GAME_SALE_DATAS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateOrganizationGameSaleDatas(game: $game) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_GAME_GAME_ASSETS_BY_TYPE = (gql: any) => gql`
    query($game: GameAuthInput, $type: String!) {
        findPrivateGameGameAssetsByType(game: $game, type: $type) {
            name
            mainImage {
                url
            }
        }
    }
`;
export const DELETE_PRIVATE_ORGANIZATION_GAME_INTEGRATION = (gql: any) => gql`
    mutation($game: GameAuthInput, $id: ID) {
        deletePrivateOrganizationGameIntegration(game: $game, id: $id) {
            id
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_SALE = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameSale: getPrivateOrganizationGameSale(game: $game, id: $id) {
            id
            status
            lastName
            firstName
            email
            phone
            paymentProvider
            paidAt
            createdAt
            tickets
            donation
            publicPageShortUrl
            paymentAmount
            paymentCurrency
            paymentProviderFeeAmount
            ownerCity
            ownerCountry
            ownerZipCode
            ownerAddress
            ownerComplement
            drawAvailabilityStatus
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_PROMISE = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGamePromise: getPrivateOrganizationGamePromise(game: $game, id: $id) {
            id
            status
            lastName
            firstName
            email
            phone
            createdAt
            tickets
            donation
            publicPageShortUrl
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_PREORDER = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGamePreorder: getPrivateOrganizationGamePreorder(game: $game, id: $id) {
            id
            status
            lastName
            firstName
            email
            phone
            createdAt
            tickets
            donation
            publicPageShortUrl
            sale
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_GODFATHER = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameGodfather: getPrivateOrganizationGameGodfather(game: $game, id: $id) {
            id
            code
            status
            firstName
            lastName
            phone
            email
            createdAt
            game
            sale
            publicPageShortUrl
            salePublicPageShortUrl
            statPaidTickets
            statPaidSales
            statPaidAmount
            statCurrentPeriodPaidTickets
            statCurrentPeriodPaidSales
            statCurrentPeriodPaidAmount
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_SELLER = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameSeller: getPrivateOrganizationGameSeller(game: $game, id: $id) {
            id
            code
            status
            firstName
            lastName
            phone
            email
            createdAt
            game
            statPaidTickets
            statPaidSales
            statPaidAmount
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_SELLERGROUP = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameSellergroup: getPrivateOrganizationGameSellergroup(game: $game, id: $id) {
            id
            code
            status
            name
            phone
            email
            createdAt
            game
            statPaidTickets
            statPaidSales
            statPaidAmount
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_TICKET = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameTicket: getPrivateOrganizationGameTicket(game: $game, id: $id) {
            id
            customCode
            code
            status
            firstName
            lastName
            phone
            email
            createdAt
            game
            sale
            publicPageShortUrl
            salePublicPageShortUrl
            price
            currency
            drawAvailabilityStatus
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_BOOK = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameBook: getPrivateOrganizationGameBook(game: $game, id: $id) {
            id
            status
            game
            code
            customCode
            lastName
            firstName
            email
            phone
            tickets
            publicPageShortUrl
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_BOOKPAYMENT = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameBookpayment: getPrivateOrganizationGameBookpayment(game: $game, id: $id) {
            id
            status
            game
            lastName
            firstName
            email
            phone
            tickets
            publicPageShortUrl
            createdAt
            donation
            paymentAmount
            paymentCurrency
            paymentProvider
            paidAt
            paymentProviderFeeAmount
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_BOOKSET = (gql: any) => gql`
    query($game: GameAuthInput, $id: ID) {
        getPrivateOrganizationGameBookset: getPrivateOrganizationGameBookset(game: $game, id: $id) {
            id
            status
            game
            publicBooksPdfUrl
            publicTicketsPdfUrl
            createdAt
            bookList
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_CHART = (gql: any) => gql`
    query($game: GameAuthInput, $chart: String) {
        getPrivateOrganizationGameChart(game: $game, chart: $chart) {
            colors
            series {
                labels
                data
            }
        }
    }
`;
export const PUBLISH_PUBLIC_PAGE_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        publishPublicPageOfPrivateOrganizationGame(game: $game) {
            id
        }
    }
`;
export const UNPUBLISH_PUBLIC_PAGE_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        unpublishPublicPageOfPrivateOrganizationGame(game: $game) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_ORGANIZATION_GAME_BLOCK = (gql: any) => gql`
    mutation($game: GameAuthInput!, $code: String!, $data: UpdatePrivateOrganizationGameBlockInput) {
        updatePrivateOrganizationGameBlock(game: $game, code: $code, data: $data) {
            id
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_GAME_BLOCK = (gql: any) => gql`
    query($game: GameAuthInput!, $id: ID!) {
        getPrivateOrganizationGameBlock(game: $game, id: $id) {
            params
            defaultValues
            modes
            fields
        }
    }
`;
export const FIND_PRIVATE_ORGANIZATION_GAME_BLOCKS = (gql: any) => gql`
    query($game: GameAuthInput!) {
        findPrivateOrganizationGameBlocks(game: $game) {
            code
            rank
            hidden
        }
    }
`;
export const SUBSCRIBE_PRIVATE_ORGANIZATION_GAME_AUTOMATIC_DRAW = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        subscribePrivateOrganizationGameAutomaticDraw(game: $game) {
            id
        }
    }
`;
export const ACTIVATE_PRIVATE_ORGANIZATION_GAME_DRAW_AUTOMATION_FEATURE = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        activatePrivateOrganizationGameDrawAutomationFeature(game: $game) {
            id
        }
    }
`;
export const DEACTIVATE_PRIVATE_ORGANIZATION_GAME_DRAW_AUTOMATION_FEATURE = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        deactivatePrivateOrganizationGameDrawAutomationFeature(game: $game) {
            id
        }
    }
`;
export const ACTIVATE_PRIVATE_ORGANIZATION_GAME_PUBLISH_RESULT_AUTOMATION_FEATURE = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        activatePrivateOrganizationGamePublishResultAutomationFeature(game: $game) {
            id
        }
    }
`;
export const DEACTIVATE_PRIVATE_ORGANIZATION_GAME_PUBLISH_RESULT_AUTOMATION_FEATURE = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        deactivatePrivateOrganizationGamePublishResultAutomationFeature(game: $game) {
            id
        }
    }
`;
export const ACTIVATE_PRIVATE_ORGANIZATION_GAME_NOTIFY_PARTICIPANTS_AUTOMATION_FEATURE = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        activatePrivateOrganizationGameNotifyParticipantsAutomationFeature(game: $game) {
            id
        }
    }
`;
export const DEACTIVATE_PRIVATE_ORGANIZATION_GAME_NOTIFY_PARTICIPANTS_AUTOMATION_FEATURE = (gql: any) => gql`
    mutation($game: GameAuthInput!) {
        deactivatePrivateOrganizationGameNotifyParticipantsAutomationFeature(game: $game) {
            id
        }
    }
`;